import React, { useRef, useState, useEffect } from "react";
import Axios from "../api/Axios";
import Breadcrumb from "./layouts/Breadcrumb";
import Loading from "./layouts/Loading";
import { getUser } from "../utils/Common";
import PerformanceAnalysis from "./layouts/graphs/PerformanceAnalysis";
import PercentwiseAnalysis from "./layouts/graphs/PercentwiseAnalysis";
import SubjectwiseAnalysis from "./layouts/graphs/SubjectwiseAnalysis";
import StudentExamAnalysis from "./layouts/dttable/StudentExamAnalysis";
import "../css/StudentPerformance.css";

const Performance = () => {
  const [requestType, setRequestType] = useState("TEST");
  const [studentPerformanceData, setStudentPerformanceData] = useState([]);
  const user = getUser();
  const errorRef = useRef();
  const [errorMsg, setErrorMsg] = useState("");
  const [loadingFlag, setLoadingFlag] = useState(false);

  const onChangeRequestType = (requestType) => {
    setRequestType(requestType);
  };

  useEffect(() => {
    setErrorMsg("");
    const fetchStudentPerformanceData = () => {
      try {
        Axios.post("/getStudentPerformance", {
          student: {
            id: user.id,
            instituteId: user.currentPackage.institute.id,
            accessType: requestType,
          },
        }).then((response) => {
          if (response.data.exams != null) {
            setLoadingFlag(false);
            setStudentPerformanceData(response.data.exams);
          } else {
            setLoadingFlag(false);
            setStudentPerformanceData([]);
            setErrorMsg("No data available");
          }
        });
      } catch (err) {
        setLoadingFlag(false);
        setErrorMsg("Something wrong");
      }
    };

    if (studentPerformanceData && !studentPerformanceData.length) {
      setLoadingFlag(true);
      fetchStudentPerformanceData();
    }

    const timeoutId = setTimeout(() => {
      if (requestType) {
        setLoadingFlag(true);
        fetchStudentPerformanceData();
      }
    }, 500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [requestType]);

  return (
    <div>
      <Breadcrumb title="Test Performance" />
      <h4 className="text-center">Attempted Tests & Performance</h4>

      <Loading flag={loadingFlag} />
      <p
        ref={errorRef}
        className={errorMsg ? "error_msg" : "offscreen"}
        aria-live="assertive"
      >
        {errorMsg}
      </p>

      <div className="d-flex align-conten-center">
        <div className="filter-data">
          <select
            className="form-select"
            onChange={(e) => onChangeRequestType(e.target.value)}
          >
            <option value="TEST">Show Only TESTS Performance</option>
            <option value="DPP">Show Only Assignments Performance</option>
            <option value="ALL">Show TESTS+Assignments Performance</option>
          </select>
        </div>
      </div>
      <PerformanceAnalysis exams={studentPerformanceData}/>
      <PercentwiseAnalysis exams={studentPerformanceData}/>
      <SubjectwiseAnalysis exams={studentPerformanceData}/>
      <StudentExamAnalysis exams={studentPerformanceData}/>
    </div>
  );
};

export default Performance;

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopyright } from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
  return (
    <footer className="footer fixed-bottom mt-auto py-3 bg-light" id="custom_footer">
      <div className="container my-auto">
        <div className="copyright text-center my-auto">
          <span>
            Copyright <FontAwesomeIcon icon={faCopyright} /> | EDOFOX
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const SubjectwiseAnalysis = (props) => {
  const testNames = props.exams
    .filter((exam) => exam.score)
    .map((item) => {
      return item.name;
    });

  // 👇️ create copy and reverse
  const testNamesReversed = [...testNames].reverse();

  const options = {
    credits: {
      enabled: false,
    },
    title: {
      text: "Subjectwise Performance",
    },
    xAxis: [
      {
        categories: testNamesReversed,
        crosshair: true,
      },
    ],
    yAxis: {
      title: {
        text: "Marks Obtained",
      },
    },
    series: [],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            legend: {
              layout: "horizontal",
              align: "center",
              verticalAlign: "bottom",
            },
          },
        },
      ],
    },
  };

  return (
    <div className="graph-card">
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default SubjectwiseAnalysis;

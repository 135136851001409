import React, { useState } from "react";
import { ReactInternetSpeedMeter } from "react-internet-meter";
import "../css/InternetSpeedTest.css";

const InternetSpeedTest = () => {
  const [wifiSpeed, setwifiSpeed] = useState("Checking ... ");

  return (
    <div class="d-flex flex-column justify-content-center align-items-center">
      <div className="align-self-center my-2">
        <ReactInternetSpeedMeter
          txtSubHeading={"Internet is too slow " + wifiSpeed + " MB/s"}
          outputType="alert"
          customClassName={null}
          txtMainHeading="Opps..."
          pingInterval={3000} // milliseconds
          thresholdUnit="megabyte" // "byte" , "kilobyte", "megabyte"
          threshold={1}
          imageUrl="https://www.sammobile.com/wp-content/uploads/2019/03/keyguard_default_wallpaper_silver.png"
          downloadSize="2550420" //bytes
          callbackFunctionOnNetworkDown={(speed) =>
            console.log(`Internet speed is down ${speed}`)
          }
          callbackFunctionOnNetworkTest={(speed) => setwifiSpeed(speed)}
        />
      </div>
      <div className="align-self-center my-2 w-100">
        <div className="wifi-card">
          <div className="text-center my-2">
            <img
              src="assets/img/icons/router.png"
              className="wifi-img"
              alt="Wifi Image"
            />
          </div>
          <div className="sub-text">{wifiSpeed} MB</div>
          <div className="title">Wifi Speed </div>
        </div>
      </div>
    </div>
  );
};

export default InternetSpeedTest;

import React from "react";
import "../css/ScheduleCard.css";

const ScheduleCard = (props) => {
  const attendanceBadge = (obj) => {
    if (obj.present != null) {
      if (obj.present === true) {
        return (
          <div>
            <span className="badge attendance_badge present-badge-color">
              P
            </span>
          </div>
        );
      } else {
        return (
          <div>
            <span className="badge attendance_badge absent-badge-color">A</span>
          </div>
        );
      }
    }
  };
  const scheduleCard = props.schedules?.map(function (item, i) {
    return (
      <div className="col-sm-12 col-md-3" key={i}>
        <div className="schedule_card">
          <div className="badge subject_badge">
            {item.subject?.subjectName.toUpperCase()}
          </div>
          <div className="card_head">
            <div className="card_title">{item.title.toUpperCase()}</div>
          </div>
          <div className="card_body">
            <div className="card_supporting_text">
              Classroom: {item.classroom.name}
            </div>
            <div className="card_supporting_text">
              {item.startsAt} - {item.endsAt}
            </div>
            <div className="card_supporting_text">
              Duration: {item.duration}
            </div>
            {attendanceBadge(item)}
          </div>
        </div>
      </div>
    );
  });

  return <div className="row">{scheduleCard}</div>;
};

export default ScheduleCard;

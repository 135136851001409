import React from "react";
import { Link } from "react-router-dom";

const Breadcrumb = (props) => {
  return (
    <div className="d-flex flex-row-reverse p-2">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link aria-current="page" to="/">
              Home
            </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
          {props.title}
          </li>
        </ol>
      </nav>
    </div>
  );
};

export default Breadcrumb;

import React, { useRef, useState, useEffect } from "react";
import Axios from "../api/Axios";
import ExamCard from "./ExamCard";
import Loading from "./layouts/Loading";
import { getUser } from "../utils/Common";
import "../css/Home.css";

const Home = () => {
  const user = getUser();
  const errorRef = useRef();
  const [testRequestType, setTestRequestType] = useState("Active");
  const [testSearch, setTestSearch] = useState("");
  const [testsData, setTestsData] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [loadingFlag, setLoadingFlag] = useState(false);

  const onChangeRequestType = (requestType) => {
    setTestRequestType(requestType);
  };

  useEffect(() => {
    setErrorMsg("");
    const fetchTestsData = () => {
      try {
        Axios.post("/getStudentExams", {
          student: {
            id: user.id,
          },
          institute: {
            id: user.currentPackage.institute.id,
          },
          requestType: testRequestType,
        }).then((response) => {
          if (response.data.exams != null) {
            setLoadingFlag(false);
            setTestsData(response.data.exams);
          } else {
            setLoadingFlag(false);
            setTestsData([]);
            setErrorMsg("No schedule available");
          }
        });
      } catch (err) {
        setLoadingFlag(false);
        setErrorMsg("Something wrong");
      }
    };

    if (testsData && !testsData.length) {
      setLoadingFlag(true);
      fetchTestsData();
    }

    const timeoutId = setTimeout(() => {
      if (testRequestType) {
        setLoadingFlag(true);
        fetchTestsData();
      }
    }, 500);

    return () => {
      clearTimeout(timeoutId);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testRequestType, testSearch]);

  return (
    <div>
      <div className="container">
        <div className="row my-4">
          <div className="col-3">
            <div className="p-3 bg-white rounded shadow d-flex align-items-center h-100 w-100">
              <div>
                <img
                  src="https://edofox-s3.s3.ap-south-1.amazonaws.com/subjectIcons/subject-exam.png"
                  className="card_img"
                  alt="subject-exam.png"
                />
              </div>

              <div className="mx-4">
                <label className="counts-subtitle">Tests Today</label>
                <h4 className="count-number" id="total_videos_count_text">
                  0
                </h4>
              </div>
            </div>
          </div>

          <div className="col-3">
            <div className="p-3 bg-white rounded shadow d-flex align-items-center h-100 w-100">
              <div>
                <img
                  src="https://edofox-s3.s3.ap-south-1.amazonaws.com/subjectIcons/subject-exam.png"
                  className="card_img"
                  alt="subject-exam.png"
                />
              </div>

              <div className="mx-4">
                <label className="counts-subtitle">Total Tests</label>
                <h4 className="count-number" id="total_videos_count_text">
                  0
                </h4>
              </div>
            </div>
          </div>

          <div className="col-3">
            <div className="p-3 bg-white rounded shadow d-flex align-items-center h-100 w-100">
              <div>
                <img
                  src="https://edofox-s3.s3.ap-south-1.amazonaws.com/public/statics/video_icon.png"
                  className="card_img"
                  alt="subject-exam.png"
                />
              </div>

              <div className="mx-4">
                <label className="counts-subtitle">DLP</label>
                <h4 className="count-number" id="total_videos_count_text">
                  0
                </h4>
              </div>
            </div>
          </div>

          <div className="col-3">
            <div className="p-3 bg-white rounded shadow d-flex align-items-center h-100 w-100">
              <div>
                <img
                  src="/assets/img/icons/calendar.png"
                  className="card_img"
                  alt="subject-exam.png"
                />
              </div>

              <div className="mx-4">
                <label className="counts-subtitle">Attendance</label>
                <h4 className="count-number" id="total_videos_count_text">
                  0
                </h4>
              </div>
            </div>
          </div>
        </div>

        <div className="row my-4">
          <div className="col-3">
            <select
              className="form-select"
              onChange={(e) => onChangeRequestType(e.target.value)}
            >
              <option value="Active">Active tests</option>
              <option value="Upcoming">Upcoming tests</option>
              <option value="Past">Past tests</option>
            </select>
          </div>
          <div className="col-3">
            <input
              type="text"
              id="test_search_box"
              className="form-control"
              autoComplete="off"
              placeholder="Search"
              onChange={(e) => setTestSearch(e.target.value)}
              value={testSearch}
            />
          </div>
        </div>

        <Loading flag={loadingFlag} />

        <p
          ref={errorRef}
          className={errorMsg ? "error_msg" : "offscreen"}
          aria-live="assertive"
        >
          {errorMsg}
        </p>

        <div className="row">
          <ExamCard exams={testsData} requestType={testRequestType} />
        </div>
      </div>
    </div>
  );
};

export default Home;

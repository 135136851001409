import React from "react";
import "../css/Instructions.css";

export default function Instructions() {
  function getChromeVersion() {
    try {
        var raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);

        return raw ? parseInt(raw[2], 10) : false;
    } catch (e) {
        console.log("Could not detect version ..");
    }

}
  function detectChrome() {
    var isChromium = window.chrome;
    var winNav = window.navigator;
    var vendorName = winNav.vendor;
    var isOpera = typeof window.opr !== "undefined";
    var isIEedge = winNav.userAgent.indexOf("Edg") > -1;
    var isIOSChrome = winNav.userAgent.match("CriOS");

    console.log(winNav.userAgent);

    if (isIOSChrome) {
        // is Google Chrome on IOS
        console.log("Is chrome");
        document.getElementById("chrome_detect").innerHTML = "You are using Google Chrome " + getChromeVersion();
        document.getElementById("chrome_detect").className("chrome-detector-success");
    } else if (
        isChromium !== null &&
        typeof isChromium !== "undefined" &&
        vendorName === "Google Inc." &&
        isOpera === false &&
        isIEedge === false
    ) {
        // is Google Chrome
        console.log("Is chrome really");
        document.getElementById("#chrome_detect").textContent = "You are using Google Chrome " + getChromeVersion();
        document.getElementById("#chrome_detect").addClass("chrome-detector-success");
    } else {
        // not Google Chrome 
        console.log("NOT chrome");
        document.getElementById("#chrome_detect").html("You are NOT using Google Chrome. Please download Google Chrome latest version from here: <a href='https://www.google.com/intl/en_in/chrome/' class='btn btn-default' target='_blank'>Download Google Chrome</a>");
        document.getElementById("#chrome_detect").addClass("chrome-detector-error");

    }
}
//detectChrome();
  return (
    <React.Fragment>
      <div className="container" id="main_div">
        <div className ="card_div" ng-if="status.statusCode == 200">
          <div id="chrome_detect" className="text-center"></div>
          <h1 id="card_box_heading">Test instructions</h1>
          <a href="index.php" className="btn btn-default">
            <i className="fa fa-arrow-left" aria-hidden="true"></i> Back
          </a>
          <div className="row">
            <div className="col-xs-12">
              <div className="instructions_page">
                <div id="only-instructions-block">
                  <ol>
                    <br />
                    <p>Please read the instructions carefully:</p>

                    <br />
                    <p>
                      <b>General Instructions:</b>
                    </p>
                    <li>
                      Total duration of examination is given in the top right
                      corner.
                    </li>
                    <li>
                      The clock will be set at the server. The countdown timer
                      in the top right corner of screen will display the
                      remaining time available for you to complete the
                      examination. When the timer reaches zero, the examination
                      will end by itself. You will not be required to end or
                      submit your examination.{" "}
                    </li>
                    <li>
                      The Question Palette displayed on the right side of screen
                      will show the status of each question using one of the
                      following symbols:
                      <p style={{ lineHeight: "43px" }}>
                        <span className="not_visited" title="Not Visited">
                          1
                        </span>{" "}
                        "Not Visited"- You have not visited the question yet.
                      </p>
                      <p style={{ lineHeight: "43px" }}>
                        <span className="not_answered" title="Not Answered">
                          2
                        </span>{" "}
                        "Not Answered" - You have not answered the question.
                      </p>
                      <p style={{ lineHeight: "43px" }}>
                        <span className="answered" title="Answered">
                          3
                        </span>{" "}
                        "Answered" - You have answered the question.
                      </p>
                      <p style={{ lineHeight: "43px" }}>
                        <span
                          className="marked_for_review"
                          title="Marked for Review"
                        >
                          4
                        </span>{" "}
                        "Marked for Review" - You have NOT answered the
                        question, but have marked the question for review.
                      </p>
                      <p style={{ lineHeight: "43px" }}>
                        <span
                          className="answered_and_marked_for_review"
                          title="Marked for Review"
                        >
                          5
                        </span>{" "}
                        "Answered and Marked for Review"- The question(s)
                        "Answered and Marked for Review" will be considered for
                        evaluation.
                        <br /> The Marked for Review status for a question
                        simply indicates that you would like to look at that
                        question again.
                      </p>
                    </li>

                    <br />
                    <br />
                    <p>
                      <b>Navigating to a Question:</b>
                    </p>
                    <li>
                      To answer a question, do the following:
                      <ol style={{ listStyle: "lower-alpha" }}>
                        <li>
                          Click on the question number in the Question Palette
                          at the right of your screen to go to that numbered
                          question directly. Note that using this option does
                          NOT save your answer to the current question.
                        </li>
                        <li>
                          Click on Save & Next to save your answer for the
                          current question and then go to the next question.
                        </li>
                        <li>
                          Click on Mark for Review & Next to save your answer
                          for the current question, mark it for review, and then
                          go to the next question.
                        </li>
                      </ol>
                    </li>

                    <br />
                    <br />
                    <p>
                      <b>Answering a Question :</b>
                    </p>
                    <li>
                      Procedure for answering a multiple choice type question:
                      <ol style={{ listStyle: "lower-alpha" }}>
                        <li>
                          To select your answer, click on the button of one of
                          the options
                        </li>
                        <li>
                          To deselect your chosen answer, click on the button of
                          the chosen option again or click on the Clear Response
                          button
                        </li>
                        <li>
                          To change your chosen answer, click on the button of
                          another option
                        </li>
                        <li>
                          To save your answer, you MUST click on the Save & Next
                          button
                        </li>
                        <li>
                          To mark the question for review, click on the Mark for
                          Review & Next button.
                        </li>
                      </ol>
                    </li>
                    <li>
                      To change your answer to a question that has already been
                      answered, first select that question for answering and
                      then follow the procedure for answering that type of
                      question.
                    </li>

                    <br />
                    <br />
                    <p>
                      <b>Navigating through sections:</b>
                    </p>
                    <li>
                      Sections in this question paper are displayed on the top
                      bar of the screen. Questions in a section can be viewed by
                      clicking on the section name. The section you are
                      currently viewing is highlighted.
                    </li>
                    <li>
                      After clicking the Save & Next button on the last question
                      for a section, you will automatically be taken to the
                      first question of the next section.
                    </li>
                    <li>
                      You can shuffle between sections and questions anytime
                      during the examination as per your convenience only during
                      the time stipulated.
                    </li>
                    <li>
                      Candidate can view the corresponding section summary as
                      part of the legend that appears in every section above the
                      question palette.
                    </li>
                  </ol>

                  {/* <div
                    id="institute_instructions"
                    style={{ paddingLeft: "16px" }}
                    ng-if="response.test.instructions != null"
                  >
                    <hr />
                    <h4 style={{ color: "#f44336" }}>Special Instructions:</h4>
                    <div
                      id="custom_instructions"
                      ng-bind-html="trustedHtml"
                    ></div>
                    <hr />
                  </div> */}
                </div>

                {/* <div
                  id="proctor_instructions"
                  ng-if="response.test.imgProctor == 1"
                >
                  <hr />

                  <h4 style={{ color: "red" }}>
                    This exam requires you to enable your camera for monitoring
                    purposes. Please make sure your device has a camera
                    otherwise change the device.
                  </h4>

                  <hr />

                  <div class="row">
                    <div class="col-sm-6">
                      <p
                        ng-if="response.test.proctoringImage != null && response.test.proctoringImage.length > 0"
                        class="text-center"
                      >
                        Your proctoring reference image is:
                        <div>
                          <img
                            src="{{response.test.proctoringImage}}"
                            class="img-responsive proctor_img"
                            alt="Proctoring"
                          />
                        </div>
                      </p>

                      <p
                        ng-if="response.test.proctoringImage == null && response.test.proctoringImage.length == 0"
                        style={{ color: "red" }}
                        class="text-center"
                      >
                        Proctoring reference image not found. Please contact
                        your administrator.
                      </p>
                    </div>

                    <div class="col-sm-6">
                      <div class="camera" style={{ textAlign: "center" }}>
                        <p>Please position yourself properly for the camera.</p>
                        <video id="video">Camera not available.</video>
                        <p>
                          Make sure there is no one else in the room while you
                          solve this exam.
                        </p>
                      </div>
                    </div>
                  </div>

                  <hr />
                </div> */}
              </div>

              {/* <div id="button_div" ng-if="videoProctoring">
                <p style={{ color: "red" }}>
                  Your video needs to remain on during the exam for invigilation
                  purposes. Please make sure the video is correctly pointed at
                  you.
                </p>

                <div id="take_photo_div">
                  <div class="row" id="camera-feed-block">
                    <div class="col-sm-6">
                      <div class="camera">
                        <video id="video">Camera not available.</video>
                      </div>
                    </div>
                  </div>

                  <div class="text-center">
                    <input
                      class="btn btn-success"
                      type="button"
                      value="START CAMERA"
                      id="camera_button"
                      ng-if="!cameraPermitted"
                      ng-click="requestCamera()"
                    />
                  </div>
                </div>
              </div> */}

              <div ng-if="!disableStartButton">
                <p>
                  By clicking START TEST below you agree to all the instructions
                  and terms above.
                </p>

                <div id="button_div">
                  <div id="start_test_button_div">
                    <input
                      className="btn btn-success"
                      type="button"
                      ng-click="startTest()"
                      value="START TEST"
                      id="start_test_button"
                    />
                  </div>
                </div>

                <p className="text-center text-muted">
                  For any technical issues and queries, please call{" "}
                  <a href="tel:7378865408">7378865408</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

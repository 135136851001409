import axios from "axios";
import { getToken } from "../utils/Common";

const token = getToken();
const instance = axios.create({
  baseURL: "https://dev.edofox.com:8443/edofox/service",
  headers: {
    AuthToken:token
  },
});

// instance.interceptors.request.use(
//   function(config) {
//     if (token) {
//       config.headers["AuthToken"] = token;
//     }
//     return config;
//   },
//   function(error) {
//     console.log(error);
//     return Promise.reject(error);
//   }
// );

export default instance;



import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const PercentwiseAnalysis = (props) => {
  const testNames = props.exams
    .filter((exam) => exam.score)
    .map((item) => {
      return item.name;
    });

  const testScoresInPercent = props.exams
    .filter((exam) => exam.score)
    .map((item) => {
      return Math.round((item.score * 100) / item.totalMarks, 2);
    });

  // 👇️ create copy and reverse
  const testNamesReversed = [...testNames].reverse();
  const testScoresInPercentReversed = [...testScoresInPercent].reverse();

  const options = {
    title: {
      text: "Percentwise Performance",
    },
    xAxis: [
      {
        categories: testNamesReversed,
        crosshair: true,
      },
    ],
    yAxis: {
      title: {
        text: "% Obtained",
      },
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: "Percentage",
        data: testScoresInPercentReversed,
      },
    ],
  };

  return (
    <div className="graph-card">
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default PercentwiseAnalysis;

import { useRef, useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Axios from "../api/Axios";
import DefaultHeader from "./layouts/DefaultHeader";
import "../css/ForgotPassword.css";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const userRef = useRef();
  const errorRef = useRef();
  const [username, setUsername] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    setErrorMsg("");
  }, [username]);

  const handleSubmit = (event) => {
    // Prevent page reload
    event.preventDefault();
  };

  return (
    <div className="container-fluid">
      <DefaultHeader />
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="card_box">
              <h5 id="title"> Forgot Password</h5>
              <form onSubmit={handleSubmit}>
                <label>Enter your Login ID/ Username:</label>
                <input
                  type="text"
                  id="username"
                  className="form-control"
                  autoComplete="off"
                  ref={userRef}
                  onChange={(e) => setUsername(e.target.value)}
                  value={username}
                  required
                />

                <div
                  className="g-recaptcha"
                  data-sitekey="<?=$_ENV['RECAPTCHA_SITE_KEY']?>"
                ></div>

                <hr />

                <p>
                  Please note that your mobile number or email needs to be
                  linked to this account for resetting password{" "}
                </p>
                <p>
                  Forgot username? Please contact your institute administrator
                </p>

                <p
                  ref={errorRef}
                  className={errorMsg ? "error_msg" : "offscreen"}
                  aria-live="assertive"
                >
                  {errorMsg}
                </p>

                <p className={successMsg ? "success_msg" : "offscreen"}>
                  {successMsg}
                </p>

                <button
                  type="submit"
                  id="forgot_password_verify_btn"
                  className="verify-btn"
                  disabled={isSubmitted}
                >
                  Verify
                </button>
              </form>
              <div className="text-center">
                <Link to="/login">Back to Login</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;

export const getUser = () => {
  const user = sessionStorage.getItem("user");
  if (user) {
    return JSON.parse(user);
  } else {
    return null;
  }
};

export const getToken = () => {
  const token = sessionStorage.getItem("token");
  if (token) {
    return token;
  } else {
    return null;
  }
};

export const setUserSession = (token, user) => {
  sessionStorage.setItem("token", token);
  sessionStorage.setItem("user", JSON.stringify(user));
};

export const removeUserSession = () => {
  sessionStorage.removeItem("token");
  sessionStorage.removeItem("user");
};

// Capitalize first letter of each word
export const capitalizeFirstLetters = (str) => {
  var splitStr = str.toLowerCase().split(" ");
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(" ");
};

// Todays date in "YYYY-mm-dd" Format
export const todays_date = () => {
  const todays_date = new Date();
  let todays_day = todays_date.getDate();
  let todays_month = todays_date.getMonth() + 1; //Month from 0 to 11
  let todays_year = todays_date.getFullYear();
  let formatted_date_today =
    todays_year +
    "-" +
    (todays_month <= 9 ? "0" + todays_month : todays_month) +
    "-" +
    (todays_day <= 9 ? "0" + todays_day : todays_day);
  return formatted_date_today;
};

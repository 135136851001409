const Loading = (props) => {
  return props.flag ? (
    <div>
      <div className="d-flex align-items-center">
        <strong>Loading...</strong>
        <div
          className="spinner-border ms-auto"
          role="status"
          aria-hidden="true"
        ></div>
      </div>
    </div>
  ) : (
    <div></div>
  );
};

export default Loading;

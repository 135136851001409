import React from "react";
import DataTable from "react-data-table-component";

import "../../../css/StudentExamAnalysis.css";

const StudentExamAnalysis = (props) => {
  const examAnalysis = props.exams?.map(function (item, i) {
    return (
      <tr key={i}>
        <td>{item.id}</td>
        <td></td>
        <td>{item.name.toUpperCase()}</td>
        <td>{item.name.toUpperCase()}</td>
        <td>{item.name.toUpperCase()}</td>
        <td>{item.name.toUpperCase()}</td>
        <td>{item.name.toUpperCase()}</td>
        <td>{item.name.toUpperCase()}</td>
      </tr>
    );
  });

  const dtColumns = props.exams?.map(function (item, i) {
    return [
      {
        name: "id",
        selector: (item) => item.id,
        sortable: true,
      },
      {
        name: "Test Name",
        selector: (item) => item.name,
        sortable: true,
      },
    ];
  });

  const dtData = props.exams?.map(function (item, i) {
    return [
      {
        id: item.id,
        name: item.name,
      },
    ];
  });

  return (
    <div>
      <table className="table table-condensed table-hover" id="testsListTable">
        <thead>
          <tr>
            <th>id</th>
            <th>#</th>
            <th>Test Name</th>
            <th>Test Date</th>
            <th>Appeared on</th>
            <th>Marks</th>
            <th>Rank</th>
            <th>Details</th>
          </tr>
        </thead>
        <tbody>{examAnalysis}</tbody>
      </table>

      <DataTable columns={dtColumns} data={dtData} />
    </div>
  );
};
export default StudentExamAnalysis;

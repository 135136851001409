import React from "react";
import "../../css/DefaultHeader.css";
const DefaultHeader = () => {
  return (
    <div className="text-center text-uppercase">
      <img
        src="/edofox-logo.png"
        className="login_institute_logo"
        alt="Institute Logo"
      />
      <h1 className="login_institute_name_title"> EDOFOX </h1>
    </div>
  );
};

export default DefaultHeader;

import React, { useRef, useState, useEffect } from "react";
import Axios from "../api/Axios";
import ScheduleCard from "./ScheduleCard";
import Breadcrumb from "./layouts/Breadcrumb";
import Loading from "./layouts/Loading";
import { getUser,todays_date } from "../utils/Common";

import "flatpickr/dist/themes/material_green.css";
import Flatpickr from "react-flatpickr";

const Attendance = () => {
  const user = getUser();
  const userRef = useRef();
  const errorRef = useRef();
  const [scheduleDate, setScheduleDate] = useState(todays_date);
  const [schedules, setSchedules] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [loadingFlag, setLoadingFlag] = useState(false);

  const onChangeDate = (dateObj) => {
    let d = dateObj.date.getDate();
    let m = dateObj.date.getMonth() + 1; //Month from 0 to 11
    let y = dateObj.date.getFullYear();
    let formatted_date =
      y + "-" + (m <= 9 ? "0" + m : m) + "-" + (d <= 9 ? "0" + d : d);
    setScheduleDate(formatted_date);
  };

  useEffect(() => {
    setErrorMsg("");

    const fetchSchedules = () => {
      console.log(scheduleDate);
      try {
        Axios.post("/getScheduleInfo", {
          schedule: {
            date: scheduleDate,
          },
          student: {
            id: user.id,
          },
        }).then((response) => {
          if (response.data.student != null) {
            setLoadingFlag(false);
            setSchedules(response.data.student.schedule);
          } else {
            setLoadingFlag(false);
            setSchedules([]);
            setErrorMsg("No schedule available");
          }
        });
      } catch (err) {
        setLoadingFlag(false);
        setErrorMsg("Something wrong");
      }
    };

    if (scheduleDate && !schedules.length) {
      setLoadingFlag(true);
      fetchSchedules();
    }

    const timeoutId = setTimeout(() => {
      if (scheduleDate) {
        setLoadingFlag(true);
        fetchSchedules();
      }
    }, 500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [scheduleDate]);

  return (
    <div>
      <Breadcrumb title="Attendance" />
      <div className="container">
        <section className="content">
          <div className="row">
            <div className="col-12 col-md-3">
              <label htmlFor="schedule_date"> Attendance Date </label>
              <Flatpickr
                options={{ dateFormat: "Y-m-d" }}
                value={scheduleDate}
                ref={userRef}
                id="schedule_date"
                onChange={([date]) => onChangeDate({ date })}
              />
            </div>
          </div>

          <Loading flag={loadingFlag} />
          <p
            ref={errorRef}
            className={errorMsg ? "error_msg" : "offscreen"}
            aria-live="assertive"
          >
            {errorMsg}
          </p>

          <div className="row equal" id="card_div">
            <ScheduleCard schedules={schedules} />
          </div>
        </section>
      </div>
    </div>
  );
};

export default Attendance;

import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const PerformanceAnalysis = (props) => {
  const testNames = props.exams
    .filter((exam) => exam.score)
    .map((item) => {
      return item.name;
    });

  const testScores = props.exams
    .filter((exam) => exam.score)
    .map((item) => {
      return item.score;
    });

  const testTotalMarks = props.exams
    .filter((exam) => exam.score)
    .map((item) => {
      return item.totalMarks;
    });

  // 👇️ create copy and reverse
  const testNamesReversed = [...testNames].reverse();
  const testScoresReversed = [...testScores].reverse();
  const testTotalMarksReversed = [...testTotalMarks].reverse();

  const options = {
    chart: {
      type: "column",
    },
    title: {
      text: "Performance",
    },
    xAxis: [
      {
        categories: testNamesReversed,
        crosshair: true,
      },
    ],
    yAxis: {
      title: {
        text: "Marks Obtained",
      },
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: "Marks Obtained",
        data: testScoresReversed,
      },
      {
        name: "Total Test Marks",
        data: testTotalMarksReversed,
      },
    ],
  };
  return (
    <div className="graph-card">
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default PerformanceAnalysis;

import { useLocation, Navigate, Outlet } from "react-router-dom";
import { getToken } from "../utils/Common";
const RequireAuth = () => {
  const { location } = useLocation();
  const token = getToken();

  return token ? (
    <Outlet />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default RequireAuth;

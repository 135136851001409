import React from "react";
import "../css/ExamCards.css";
import { useNavigate } from "react-router-dom";

const ExamCard = (props) => {
  const navigate = useNavigate();
  //console.log(props.requestType)
  const testCards = props.exams.map(function (item, i) {
    return (
      <div className="col-4" key={i}>
        <div className="card_box_past_tests">
          <h5 className="test_name">{item.name}</h5>
          <table className="table table-condensed">
            <tbody>
              <tr>
                <td>Test Starts At</td>
                <td>
                  <b>{item.startDateString}</b>
                </td>
              </tr>
              <tr>
                <td>Login Deadline</td>
                <td>
                  <b>{item.endDateString}</b>
                </td>
              </tr>
              <tr>
                <td>Test Duration</td>
                <td>
                  <b>{item.duration}</b>
                </td>
              </tr>
              <tr>
                <td>Classroom</td>
                <td>
                  <b>{item.packageName}</b>
                </td>
              </tr>
            </tbody>
          </table>
          {props.requestType === "Active" ?  <div style={{display:"flex",justifyContent: "space-between",alignItems: "center"}}><button className="start_test_button" onClick={()=>navigate('/testInstructions')} name="test_frm">Start Test</button></div> : null}
         
        </div>
      </div>
    );
  });

  return <div className="row">{testCards}</div>;
};

export default ExamCard;

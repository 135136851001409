import React from "react";
import { getUser, capitalizeFirstLetters } from "../utils/Common";
import Breadcrumb from "./layouts/Breadcrumb";
import "../css/Profile.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faMobileAlt } from "@fortawesome/free-solid-svg-icons";

const Profile = () => {
  const user = getUser();
  console.log(user);
  return (
    <div>
      <Breadcrumb title="Profile" />
      <div className="d-flex justify-content-center">
        <div className="card shadow profile_card p-4 my-2">
          <div className="text-center">
            <img
              src="/edofox-logo.png"
              alt="profile image"
              className="img-fluid img-thumbnail profile_card_img"
            />

            <div className="profile_card_title my-2">
              {capitalizeFirstLetters(user.rollNo)}
            </div>
            <div className="profile_card_subtitle">
              {capitalizeFirstLetters(user.name)}
            </div>
            <div className="profile_card_text">
              <FontAwesomeIcon icon={faEnvelope} /> {user.email}{" "}
              <FontAwesomeIcon icon={faMobileAlt} /> {user.phone}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;

import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import RequireAuth from "./components/RequireAuth";
import Login from "./components/Login";
import ForgotPassword from "./components/ForgotPassword";
import Template from "./components/layouts/Template";
import Home from "./components/Home";
import Dlp from "./components/Dlp";
import Attendance from "./components/Attendance";
import Lectures from "./components/Lectures";
import Performance from "./components/Performance";
import QuestionBank from "./components/QuestionBank";
import StudentDoubts from "./components/StudentDoubts";
import LiveClassroom from "./components/LiveClassroom";
import InternetSpeedTest from "./components/InternetSpeedTest";
import Profile from "./components/Profile";
import Instructions from "./components/Instructions";

const App = () => {
  return (
    <Router>
      <Routes>
        {/* public routes */}
        <Route path="/login" element={<Login />} />
        <Route path="/forgotPassword" element={<ForgotPassword />} />
        {/* we want to protect these routes */}
        <Route element={<RequireAuth />}>
          <Route path="/" element={<Template />}>
            <Route exact path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/dlp" element={<Dlp />} />
            <Route path="/performance" element={<Performance />} />
            <Route path="/attendance" element={<Attendance />} />
            <Route path="/lectures" element={<Lectures />} />
            <Route path="/questionBank" element={<QuestionBank />} />
            <Route path="/studentDoubts" element={<StudentDoubts />} />
            <Route path="/liveClassroom" element={<LiveClassroom />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/internetSpeedTest" element={<InternetSpeedTest />} />
            <Route path="/testInstructions" element={<Instructions />} />
          </Route>
        </Route>
      </Routes>
    </Router>
  );
};
export default App;

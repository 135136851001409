import { useRef, useState, useEffect, useContext } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Axios from "../api/Axios";
import axios from "axios";
import AuthContext from "../context/AuthProvider";
import DefaultHeader from "./layouts/DefaultHeader";
import { setUserSession } from "../utils/Common";
import "../css/Login.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserCircle,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";

const Login = () => {
  const { setAuth } = useContext(AuthContext);
  const { setUserDetails } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const userRef = useRef();
  const errorRef = useRef();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [togglePasswordIcon, setTogglePasswordIcon] = useState(true);
  const [successMsg, setSuccessMsg] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    setErrorMsg("");
  }, [username, password]);

  const togglePassword = (e) => {
    var x = document.getElementById("password");
    if (x.type === "password") {
      x.type = "text";
      setTogglePasswordIcon(false);
    } else {
      x.type = "password";
      setTogglePasswordIcon(true);
    }
  };

  const handleSubmit = (event) => {
    // Prevent page reload
    event.preventDefault();
    setIsSubmitted(true);
    try {
      axios.post("https://dev.edofox.com:8443/edofox/service/login", {
        student: {
          rollNo: username,
          password: password,
        },
      }).then((response) => {
        // console.log(response);
        if (response.data.status.statusCode === 200) {
          const accessToken = response.data.student.token;
          Axios.defaults.headers.common['AuthToken'] = accessToken;
          setAuth({ accessToken: accessToken });
          setUserDetails({
            studentId: response.data.student.id,
            rollNo: response.data.student.rollNo,
            name: response.data.student.name,
            phone: response.data.student.phone,
            email: response.data.student.email,
            instituteId: response.data.student.currentPackage.institute.id,
            instituteName: response.data.student.currentPackage.institute.name,
          });
          setUserSession(accessToken, response.data.student);

          setUsername("");
          setPassword("");
          setSuccessMsg("User successfully logged in");
          setErrorMsg("");
          setIsSubmitted(false);
          navigate(from, { replace: true });
        } else {
          if (
            response.data.status.responseText !== null &&
            response.data.status.responseText !== ""
          ) {
            setSuccessMsg("");
            setErrorMsg(response.data.status.responseText);
          }
          setIsSubmitted(false);
          return false;
        }
      });
    } catch (e) {
      setSuccessMsg("");
      setErrorMsg(
        "Your username or password does not match our records. Try again."
      );
      setIsSubmitted(false);
    }
  };

  const resetInputs = (e) => {
    setUsername("");
    setPassword("");
  };

  return (
    <div className="container-fluid">
      <DefaultHeader />
      <div className="container" id="main_div">
        <div className="card_box">
          <h5 id="title"> Login</h5>
          <hr />
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label className="form-label" htmlFor="username">
                Username
              </label>
              <div className="input-group">
                <span
                  className="input-group-text input-group-addon"
                  id="username-addon"
                >
                  <FontAwesomeIcon icon={faUserCircle} />
                </span>
                <input
                  type="text"
                  id="username"
                  className="form-control"
                  autoComplete="off"
                  ref={userRef}
                  onChange={(e) => setUsername(e.target.value)}
                  value={username}
                  required
                />
              </div>
            </div>

            <div className="mb-3">
              <label className="form-label" htmlFor="password">
                Password
              </label>
              <div className="input-group">
                <span
                  className="input-group-text input-group-addon"
                  id="password-display-toggle"
                  onClick={(e) => togglePassword(e)}
                >
                  <FontAwesomeIcon
                    id="password_icon"
                    icon={togglePasswordIcon ? faEyeSlash : faEye}
                  />
                </span>
                <input
                  id="password"
                  type="password"
                  className="form-control"
                  autoComplete="off"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  required
                />
              </div>
            </div>

            <p
              ref={errorRef}
              className={errorMsg ? "error_msg" : "offscreen"}
              aria-live="assertive"
            >
              {errorMsg}
            </p>

            <p className={successMsg ? "success_msg" : "offscreen"}>
              {successMsg}
            </p>

            <button
              type="submit"
              id="login_validate_btn"
              className="login_button"
              disabled={isSubmitted}
            >
              Login
            </button>

            <button
              type="reset"
              className="reset_button"
              disabled={isSubmitted}
              onClick={resetInputs}
            >
              Reset
            </button>
          </form>
          <div className="text-center">
            <Link to="/forgotPassword">Forgot Password</Link>
          </div>
        </div>

        <div className="text-center"></div>
      </div>

      <div id="keyboard-div"></div>
    </div>
  );
};

export default Login;

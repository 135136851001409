import React from "react";
import { useNavigate, Link } from "react-router-dom";
import { getUser, removeUserSession } from "../../utils/Common";
import "../../css/Header.css";


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook, faChartColumn, faCalendarCheck, faVideoCamera, faVideo, faQuestionCircle, faUniversity, faTachometer, faUserCircle, faSignOut, faMobileAlt } from "@fortawesome/free-solid-svg-icons";

const Header = () => {
  const user = getUser();
  const navigate = useNavigate();

  const logout = () => {
    removeUserSession();
    navigate("/login");
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-fixed-top" id="topnavbar">
      <div className="container-fluid">
        <div className="d-flex align-items-center">
          <Link className="navbar-brand" to="/">
            {user.currentPackage.institute.name}
          </Link>
        </div>

        <button
          className="navbar-toggler border-0"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div
          className="collapse navbar-collapse justify-content-end"
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link className="nav-link active" aria-current="page" to="/">
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link active" aria-current="page" to="/dlp">
              <FontAwesomeIcon icon={faBook} /> My Learning
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/performance">
              <FontAwesomeIcon icon={faChartColumn} /> My Performance
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/attendance">
              <FontAwesomeIcon icon={faCalendarCheck} /> Attendance
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/lectures">
              <FontAwesomeIcon icon={faVideoCamera} /> Videos
              </Link>
            </li>
            <li className="nav-item dropdown">
              <Link
                className="nav-link dropdown-toggle"
                to="#"
                id="moreOptionsDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                More
              </Link>
              <ul
                className="dropdown-menu"
                aria-labelledby="moreOptionsDropdown"
              >
                <li>
                  <Link className="dropdown-item" to="/liveClassroom">
                  <FontAwesomeIcon icon={faVideo} /> Live
                    Classroom
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/studentDoubts">
                  <FontAwesomeIcon icon={faQuestionCircle} /> Doubts
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/questionBank">
                  <FontAwesomeIcon icon={faUniversity} />  Questions
                    Bank
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/internetSpeedTest">
                  <FontAwesomeIcon icon={faTachometer} />  Internet
                    Speedtest
                  </Link>
                </li>
              </ul>
            </li>

            <li className="nav-item dropdown">
              <Link
                className="nav-link dropdown-toggle"
                to="#"
                id="supportDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Support
              </Link>
              <ul className="dropdown-menu" aria-labelledby="supportDropdown">
                <li>
                  <Link className="dropdown-item" to="tel:7378865408">
                  <FontAwesomeIcon icon={faMobileAlt} /> 7378865408
                  </Link>
                </li>
              </ul>
            </li>

            <li className="nav-item dropdown">
              <Link
                className="nav-link dropdown-toggle"
                to="#"
                id="profileDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <div className="user-image"></div>
                <span>{user.name}</span>
              </Link>
              <ul className="dropdown-menu" aria-labelledby="profileDropdown">
                <li>
                  <Link className="dropdown-item" to="/profile">
                  <FontAwesomeIcon icon={faUserCircle} /> Profile
                  </Link>
                </li>
              </ul>
            </li>

            <li className="nav-item">
              <Link className="nav-link" to="/" onClick={logout}>
              <FontAwesomeIcon icon={faSignOut} /> Logout
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
